import type { LocaleObject } from '@nuxtjs/i18n/dist/runtime/composables'
import { useIndustries } from '~/composables/cms'
import type { Industry_Plain } from '@webamboos/admin'
import { useTechnologies } from '~/composables/cms'

interface BaseItem {
  label: string
}

interface ExternalLink extends BaseItem {
  type: 'external'
  href: string
}
interface InternalLink extends BaseItem {
  type: 'internal'
  to: string
  notification?: string
}
interface LocaleLink extends BaseItem {
  locale: LocaleObject
}

type NavLink = ExternalLink | InternalLink
export interface SubNav extends BaseItem {
  type: 'subnav' | 'subnav-work'
  links: NavLink[]
  highlight?: NavLink & { body: string }
}

export interface LocaleNav extends BaseItem {
  type: 'locale'
  locales: LocaleLink[]
}

export type NavItem = ExternalLink | InternalLink | SubNav | LocaleNav
export type Nav = NavItem[]

export async function useNav() {
  const { t } = useI18n()
  const p = useLocalePath()

  const { fetch: fetchIndustries } = useIndustries()
  const { fetch: fetchTechnologies } = useTechnologies()
  const { fetch: fetchJobs } = useJobs()

  const { data: industries } = await useAsyncData('industriesNav', async () => await fetchIndustries())
  const { data: technologies } = await useAsyncData('technologiesNav', async () => await fetchTechnologies())
  const { data: jobs } = await useAsyncData('jobs', async () => await fetchJobs())

  //! to be used later when we have german and other languages.
  // const localeLinks = locales.value
  //   .map((locale) => {
  //     if (typeof locale === 'string') return
  //     return {
  //       locale,
  //     }
  //   })
  //   .filter(Boolean) as LocaleLink[]

  return [
    {
      type: 'subnav',
      label: t('header.nav.company'),
      highlight: {
        type: 'internal',
        label: t('header.nav.about'),
        body: t('header.nav.about_body'),
        to: p('/company'),
      },
      links: [
        {
          type: 'internal',
          label: t('header.nav.careers'),
          to: p('/careers'),
          notification: jobs.value?.length ? String(jobs.value.length) : undefined,
        },
        {
          type: 'internal',
          label: t('header.nav.startups'),
          to: p('/startups'),
        },
        {
          type: 'internal',
          label: t('header.nav.impressum'),
          to: p('/impressum'),
        },
      ],
    },
    {
      type: 'subnav-work',
      label: t('header.nav.services'),
      links: [
        {
          type: 'internal',
          label: t('header.nav.product_discovery_workshop'),
          to: p('/product-discovery-workshop'),
        },
        {
          type: 'internal',
          label: t('header.nav.managed_projects'),
          to: p('/managed-projects'),
        },
        {
          type: 'internal',
          label: t('header.nav.dedicated_team'),
          to: p('/dedicated-team'),
        },
      ],
    },
    {
      type: 'subnav-work',
      label: t('header.nav.technologies'),
      links: technologies
        .value!.filter((o) => o.slug)
        .map((technology) => ({
          type: 'internal',
          label: technology.name,
          to: p(`/tech/${technology.slug}`) || '',
        })),
    },
    {
      type: 'subnav-work',
      label: t('header.nav.projects'),
      links: industries
        .value!.filter((industry) => industry.slug)
        .map((industry) => ({
          type: 'internal',
          label: industry.name,
          to: p(`/projects/${industry.slug}`) || '',
        })),
    },
    // { type: 'internal', label: t('header.nav.projects'), to: p('/projects') },
    { type: 'internal', label: t('header.nav.blog'), to: p('/blog') },
    // { type: 'locale', label: t('header.nav.language'), locales: localeLinks },
  ] satisfies Nav
}

export const flagNames = {
  de: 'de',
  en: 'gb',
  ro: 'ro',
} as Record<string, string>

export function toFlagIcon(code: string) {
  return `flag:${flagNames[code]}-4x3`
}
