<script setup lang="ts">
const { industries } = useHome()
</script>

<template v-if="industries">
  <Container class="screen-content p-2 lg:p-4 lg:px-2 lg:py-8">
    <div class="grid grid-cols-4 gap-3 md:grid-cols-8 lg:grid-cols-12 lg:gap-6">
      <NuxtLink
        to="/logistics"
        class="group col-span-4 flex flex-col justify-between rounded-lg border-4 border-white bg-gradient-to-b from-cyan-200 from-10% via-yellow-50 to-white to-90% p-4 shadow md:col-span-4 lg:col-span-3 lg:row-span-2 lg:border-8 lg:p-6"
      >
        <div
          class="flex h-16 w-16 items-center justify-center rounded-full border-4 border-white lg:h-20 lg:w-20 lg:border-8"
        >
          <div
            class="bg-main-100 flex h-12 w-12 items-center justify-center rounded-full p-3 text-white lg:h-16 lg:w-16 lg:p-4"
          >
            <img
              :src="industries?.industry_cards.find((l) => l.title === 'Logistics')?.icon?.url"
              class="h-full w-full object-contain"
            />
            <Icon class="h-6 w-6 lg:h-8 lg:w-8" name="material-symbols:local-shipping-outline" />
          </div>
        </div>
        <div>
          <Heading
            as="h3"
            class="text-main-900 mb-2 mt-6 flex items-center gap-2 text-base font-medium lg:mt-8 lg:text-lg"
          >
            {{ industries?.industry_cards.find((l) => l.title === 'Logistics')?.title }}
            <Icon class="invisible rotate-90 group-hover:visible" name="material-symbols:arrow-insert-rounded" />
          </Heading>
          <Paragraph class="mt-2 lg:mt-4" size="small">
            {{ industries?.industry_cards.find((l) => l.title === 'Logistics')?.description }}
          </Paragraph>
        </div>
      </NuxtLink>

      <NuxtLink
        to="/projects/edtech"
        class="group col-span-4 cursor-pointer rounded-lg p-2 text-center shadow md:col-span-8 lg:col-span-6"
      >
        <div
          class="hover:bg-main-100 flex h-full flex-col justify-between gap-4 rounded-lg border p-4 transition-all lg:flex-row lg:gap-8 lg:p-8"
        >
          <div
            class="border-main-50 mx-auto flex h-16 w-16 items-center justify-center rounded-full border-4 lg:mx-0 lg:h-20 lg:w-20 lg:border-8"
          >
            <div
              class="bg-main-100 flex h-12 w-12 items-center justify-center rounded-full p-3 text-white lg:h-16 lg:w-16 lg:p-4"
            >
              <img
                :src="industries?.industry_cards.find((l) => l.title === 'EdTech')?.icon?.url"
                class="h-full w-full object-contain"
              />
              <Icon class="h-6 w-6 lg:h-8 lg:w-8" name="material-symbols:school-outline" />
            </div>
          </div>
          <div class="text-center lg:text-left">
            <Heading as="h3" class="mb-2 flex items-center gap-2 text-sm font-medium lg:mb-4 lg:text-base">
              {{ industries?.industry_cards.find((f) => f.title === 'EdTech')?.title }}
              <Icon class="invisible rotate-90 group-hover:visible" name="material-symbols:arrow-insert-rounded" />
            </Heading>
            <Paragraph size="small">
              {{ industries?.industry_cards.find((f) => f.title === 'EdTech')?.description }}
            </Paragraph>
          </div>
        </div>
      </NuxtLink>

      <NuxtLink
        to="projects/food-and-beverages"
        class="group col-span-4 cursor-pointer rounded-lg bg-white p-2 shadow md:col-span-4 lg:col-span-3 lg:row-span-2"
      >
        <div
          class="hover:bg-main-100 flex h-full flex-col justify-between gap-3 rounded-lg border p-4 transition-all lg:gap-4 lg:p-6"
        >
          <div
            class="border-main-50 flex h-16 w-16 items-center justify-center rounded-full border-4 lg:h-20 lg:w-20 lg:border-8"
          >
            <div
              class="bg-main-100 flex h-12 w-12 items-center justify-center rounded-full p-3 text-white lg:h-16 lg:w-16 lg:p-4"
            >
              <img
                :src="industries?.industry_cards.find((l) => l.title === 'Food & Beverages')?.icon?.url"
                class="h-full w-full object-contain"
              />
            </div>
          </div>
          <div class="text-left">
            <Heading as="h3" class="mb-2 flex items-center gap-2 text-base font-medium lg:text-lg">
              {{ industries?.industry_cards.find((f) => f.title === 'Food & Beverages')?.title }}
              <Icon class="invisible rotate-90 group-hover:visible" name="material-symbols:arrow-insert-rounded" />
            </Heading>
            <Paragraph size="small">
              {{ industries?.industry_cards.find((f) => f.title === 'Food & Beverages')?.description }}
            </Paragraph>
          </div>
        </div>
      </NuxtLink>

      <NuxtLink
        to="/projects"
        class="group col-span-4 flex h-full cursor-pointer flex-col items-center justify-center rounded-lg bg-gradient-to-b from-teal-200 via-green-100 to-teal-100 p-4 text-center shadow md:col-span-8 lg:col-span-6 lg:row-span-2 lg:p-6"
      >
        <Heading
          class="flex items-center gap-2 bg-gradient-to-r from-teal-800 via-teal-700 to-teal-950 bg-clip-text text-transparent"
        >
          {{ $t('home.industries.title') }}
          <Icon class="invisible rotate-90 group-hover:visible" name="material-symbols:arrow-insert-rounded" />
        </Heading>
        <Paragraph size="medium" class="mb-4 mt-4 w-full lg:w-2/3">{{ $t('home.industries.description') }}</Paragraph>
        <Action intent="main">{{ $t('home.industries.actionLabel') }}</Action>
      </NuxtLink>

      <NuxtLink
        to="/projects/fintech"
        class="group col-span-4 cursor-pointer rounded-lg bg-white p-2 shadow md:col-span-4 lg:col-span-3 lg:row-span-2"
      >
        <div class="hover:bg-main-100 flex h-full flex-col justify-between rounded-lg border p-4 transition-all lg:p-6">
          <div
            class="border-main-50 flex h-16 w-16 items-center justify-center rounded-full border-4 lg:h-20 lg:w-20 lg:border-8"
          >
            <div
              class="bg-main-100 flex h-12 w-12 items-center justify-center rounded-full p-3 text-white lg:h-16 lg:w-16 lg:p-4"
            >
              <img
                :src="industries?.industry_cards.find((l) => l.title === 'FinTech')?.icon?.url"
                class="h-full w-full object-contain"
              />
              <Icon class="h-6 w-6 lg:h-8 lg:w-8" name="material-symbols:payments-outline" />
            </div>
          </div>
          <div class="text-left">
            <Heading as="h3" class="mb-2 flex items-center gap-2 text-base font-medium lg:text-lg">
              {{ industries?.industry_cards.find((f) => f.title === 'FinTech')?.title }}
              <Icon class="invisible rotate-90 group-hover:visible" name="material-symbols:arrow-insert-rounded" />
            </Heading>
            <Paragraph size="small">
              {{ industries?.industry_cards.find((f) => f.title === 'FinTech')?.description }}
            </Paragraph>
          </div>
        </div>
      </NuxtLink>

      <NuxtLink
        to="projects/manufacturing"
        class="group col-span-4 cursor-pointer rounded-lg border bg-white p-2 shadow md:col-span-4 lg:col-span-3"
      >
        <div
          class="hover:bg-main-100 flex flex-col justify-between gap-4 rounded-lg border p-4 transition-all lg:gap-20 lg:p-6"
        >
          <div
            class="border-main-50 flex h-16 w-16 items-center justify-center rounded-full border-4 lg:h-20 lg:w-20 lg:border-8"
          >
            <div
              class="bg-main-100 flex h-12 w-12 items-center justify-center rounded-full p-3 text-white lg:h-16 lg:w-16 lg:p-4"
            >
              <img
                :src="industries?.industry_cards.find((l) => l.title === 'Manufacturing')?.icon?.url"
                class="h-full w-full object-contain"
              />
              <Icon class="h-6 w-6 lg:h-8 lg:w-8" name="material-symbols:precision-manufacturing-outline" />
            </div>
          </div>
          <div>
            <Heading as="h3" class="mb-2 flex items-center gap-2 text-base font-medium lg:text-lg">
              {{ industries?.industry_cards.find((f) => f.title === 'Manufacturing')?.title }}
              <Icon class="invisible rotate-90 group-hover:visible" name="material-symbols:arrow-insert-rounded" />
            </Heading>
            <Paragraph size="small">
              {{ industries?.industry_cards.find((f) => f.title === 'Manufacturing')?.description }}
            </Paragraph>
          </div>
        </div>
      </NuxtLink>

      <NuxtLink
        to="projects/warehouse"
        class="group col-span-4 cursor-pointer rounded-lg p-2 text-center shadow md:col-span-4 lg:col-span-4"
      >
        <div
          class="hover:bg-main-100 flex h-full flex-col items-center justify-between gap-4 rounded-lg border p-4 transition-all lg:flex-row lg:gap-8 lg:p-8"
        >
          <div
            class="border-main-50 flex h-16 w-16 items-center justify-center rounded-full border-4 lg:h-20 lg:w-20 lg:border-8"
          >
            <div
              class="bg-main-100 flex h-12 w-12 items-center justify-center rounded-full p-3 text-white lg:h-16 lg:w-16 lg:p-4"
            >
              <img
                :src="industries?.industry_cards.find((l) => l.title === 'Warehouse')?.icon?.url"
                class="h-full w-full object-contain"
              />
              <Icon class="h-6 w-6 lg:h-8 lg:w-8" name="material-symbols:warehouse-outline" />
            </div>
          </div>
          <div class="text-center lg:text-left">
            <Heading as="h3" class="mb-2 flex items-center gap-2 text-sm font-medium lg:mb-4 lg:text-base">
              {{ industries?.industry_cards.find((f) => f.title === 'Warehouse')?.title }}
              <Icon class="invisible rotate-90 group-hover:visible" name="material-symbols:arrow-insert-rounded" />
            </Heading>
            <Paragraph size="small">
              {{ industries?.industry_cards.find((f) => f.title === 'Warehouse')?.description }}
            </Paragraph>
          </div>
        </div>
      </NuxtLink>

      <NuxtLink
        to="projects/medtech"
        class="group col-span-4 flex cursor-pointer flex-col justify-center rounded-lg border bg-white p-2 shadow-sm md:col-span-8 lg:col-span-5"
      >
        <div
          class="hover:bg-main-100 flex h-full flex-col items-center justify-between gap-4 rounded-lg border p-4 transition-all lg:flex-row lg:gap-8 lg:p-8"
        >
          <div
            class="border-main-50 flex h-16 w-16 items-center justify-center rounded-full border-4 lg:h-20 lg:w-20 lg:border-8"
          >
            <div
              class="bg-main-100 flex h-12 w-12 items-center justify-center rounded-full p-3 text-white lg:h-16 lg:w-16 lg:p-4"
            >
              <img
                :src="industries?.industry_cards.find((l) => l.title === 'Manufacturing')?.icon?.url"
                class="h-full w-full object-contain"
              />
              <Icon class="h-6 w-6 lg:h-8 lg:w-8" name="material-symbols:health-and-safety-outline" />
            </div>
          </div>
          <div class="text-center lg:text-left">
            <Heading as="h3" class="mb-2 flex items-center gap-2 text-sm font-medium lg:mb-4 lg:text-base">
              {{ industries?.industry_cards.find((f) => f.title === 'Manufacturing')?.title }}
              <Icon class="invisible rotate-90 group-hover:visible" name="material-symbols:arrow-insert-rounded" />
            </Heading>
            <Paragraph size="small">
              {{ industries?.industry_cards.find((f) => f.title === 'Manufacturing')?.description }}
            </Paragraph>
          </div>
        </div>
      </NuxtLink>
    </div>
  </Container>
</template>
